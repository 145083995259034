/**
 * Theme global.js
 */

function init() {
    'use strict';

    //
}

document.addEventListener('DOMContentLoaded', init);

(function ($) {
    $(document).ready(function () {

        $('.js-tilt').tilt({
            glare: true,
            maxGlare: .5,
            speed: 3000
        });

        $('.hamburger').on('click', function (e) {
            $(this).toggleClass('is-active');
            $('header.main .mobile-menu').slideToggle(300);
            $('body').toggleClass('mobile-closed mobile-open');
        });

        $('.mobile-menu i').on('click', function (e) {
            e.preventDefault();
            $(this).toggleClass('open');
            $(this).parent().siblings('.child-sub-menu').slideToggle(150);
        });


        $('.category.more').on('click', function () {
            $('.category-cloud .category').removeClass('hidden');
            $(this).hide();
        })

        const swiper = new Swiper('.swiper', {
            direction: 'horizontal',
            loop: true,
            effect: 'fade',
            speed: 750,
            autoplay: true,
            delay: 2500
        });

        const image_carousel = new Swiper('.image-carousel', {
            direction: 'horizontal',
            loop: true,
            effect: 'slide',
            speed: 750,
            autoplay: true,
            delay: 1000
        });

        let sub_menu_li = $('.sub-menu-container .sub-menu > li');
        let sub_menu_bg = $('.sub-menu-background');
        let max_height = 0
        $('.sub-menu-container .child-sub-menu').each(function () {
            let height = $(this).outerHeight();
            if (height > max_height) {
                max_height = height;
            }
        });
        sub_menu_bg.css('height', max_height);

        sub_menu_li.mouseover(function () {
            if ($(this).find('.child-sub-menu').length !== 0) {
                sub_menu_bg.addClass('active');
                sub_menu_bg.removeClass('passive');
            }
        });
        sub_menu_li.mouseout(function () {
            sub_menu_bg.removeClass('active');
            sub_menu_bg.addClass('passive');
        });

        $('.pair .question').on('click', function () {
            $(this).siblings('.answer').slideToggle(300);
            $(this).children('.plus').toggleClass('open closed');
        })

        $('span.qa-category').on('click', function () {
            let url = window.location.href.split(/[?#]/g)[0];
            let id = $(this).attr('id');
            url = url + '?qa-category=' + id + '#qa-categories-anchor';
            window.location.href = url;
        })

        $('span.qa-category-all').on('click', function () {
            let url = window.location.href.split(/[?#]/g)[0];
            url = url + '#qa-categories-anchor';
            window.location.href = url;
        })

        //404
        if ($('body.error404 div.content').length) {
            let window_height = $(window).height();
            let header_footer_height = $('header.main').outerHeight() + $('footer.main').outerHeight();
            let container_height = window_height - header_footer_height;
            $('body.error404 div.content').css('min-height', container_height);
        }

        fullScreenHeroHeight();

    });

    $(window).resize(function () {
        fullScreenHeroHeight();
    });

    function fullScreenHeroHeight() {
        let footer_height = $('footer.main').outerHeight();
        let special_hero_height = window.height - footer_height;
        $('section.special-hero').css('height', special_hero_height);
    }

})(jQuery);
